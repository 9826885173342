<template>
  <section>
    <div class="listHeader">
      <h1 class="pl-3 homeHeader">Users</h1>
    </div>
    <div class="w-full">
      <div class="hr pb-2 pl-3 mb-4">
        <h2>Owner</h2>
        <p class="options text-white">
          <span>Full access</span> | Control of all accounts, permissions and settings
        </p>
      </div>
      <userlisting
        :key="userLookup.Owner.name"
        :name="userLookup.Owner.name"
        :title="userLookup.Owner.title"
        :phone="userLookup.Owner.phone"
        :email="userLookup.Owner.email"
      ></userlisting>
      <div class="hr pb-2 pl-3 pt-10 mb-4">
        <h2>Property Manager</h2>
        <p class="options text-white">
          <span>Limited access</span> | Add/Remove User | Change Password | Assign Tasks | Assign
          Roles | Create Roles
        </p>
      </div>
      <userlisting
        :key="userLookup['Property Manager'].name"
        :name="userLookup['Property Manager'].name"
        :title="userLookup['Property Manager'].title"
        :phone="userLookup['Property Manager'].phone"
        :email="userLookup['Property Manager'].email"
      ></userlisting>
      <userlisting
        v-if="userLookup['Property Manager 2'].name"
        :key="userLookup['Property Manager 2'].name"
        :name="userLookup['Property Manager 2'].name"
        :title="userLookup['Property Manager 2'].title"
        :phone="userLookup['Property Manager 2'].phone"
        :email="userLookup['Property Manager 2'].email"
      ></userlisting>
      <div class="hr pb-2 pl-3 pt-10 mb-4">
        <h2>Maintenance</h2>
        <p class="options text-white">
          <span>Limited access</span> | See Service Data | Complete Tasks | Control Unit | Recieve
          Alerts | Aux Settings
        </p>
      </div>
      <userlisting
        :key="userLookup['Maintenance'].name"
        :name="userLookup['Maintenance'].name"
        :title="userLookup['Maintenance'].title"
        :phone="userLookup['Maintenance'].phone"
        :email="userLookup['Maintenance'].email"
      ></userlisting>
    </div>
    <div class="absolute bottom-0 right-0 p-5 align-right text-18 grid grid-cols-2 gap-5">
      <button class="cursor-not-allowed grey px-10">ADD ROLE</button>
      <button class="cursor-not-allowed yellow px-10">ADD USER</button>
    </div>
  </section>
</template>
font-family: Poppins-Medium; font-size: 12px; color: #F2A900; letter-spacing: 0;
<script>
  // import propertiesPageHeader from '@/components/propertiesPageHeader.vue';
  import userlisting from '@/components/usersListItem.vue';
  import { mapState } from 'vuex';

  export default {
    components: { userlisting },
    data() {
      return {};
    },
    computed: {
      ...mapState({
        userLookup: (state) =>
          state.users.reduce((acc, user) => {
            acc[user.title] = user;
            return acc;
          }, {}),
      }),
    },
    mounted() {},
    watch: {},
    methods: {},
  };
</script>
<style lang="scss" scope>
  @import '../styles/typovars';
  h2 {
    text-transform: uppercase;
    font-size: 16px;
    color: #ffffff;
    letter-spacing: 0;
  }
  .options {
    color: #979797;
    font-size: 12px;
    span {
      color: $yellow;
    }
  }
</style>
