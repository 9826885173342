<template>
  <div @click="userClick" class="w-full relative rounded grey p-2 mb-3">
  <!-- <router-link :to="userURL" tag="li" class="w-full relative rounded grey p-2 mb-3"> -->
    <!-- <div class="w-full relative rounded grey p-2 mb-2" @click="userClick"> -->
    <div class="grid grid-cols-7 gap-5">
      <div class="col-span-3 text-white font-extrabold text-20 my-auto p-2">{{ userName }}</div>
      <div class="col-span-2 text-white text-16 border-left my-auto p-2">{{ userEmail }}</div>
      <div class="col-span-2 text-white text-16 border-left my-auto p-2">{{ userPhone }}</div>
     <!-- <div class="col-span-1 text-16 text-white border-left my-auto p-2">{{ userOffline }}</div>
       <div
        class="arrow-cta col-span-1 font-extrabold text-20 border-left my-auto p-2"
        :class="{ 'text-red': errors > 0, 'text-ltgrey': errors == 0 }"
      >
        {{ userErrors }}<span class="right-2"></span>
      </div> -->
    </div>
  </div>
  <!-- </router-link> -->
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: 'Susan Jones',
    },
    email: {
      type: String,
      default: 'name@email.com',
    },
    phone: {
      type: String,
      default: '123-456-7890',
    },
    role: {
      type: String,
      default: '1234',
    },
    url: {
      type: String,
      default: '/users/bob-stevens',
    },
  },
  components: {},
  data() {
    return {
      userName: this.name,
      userAddress: this.address,
      userEmail: this.email,
      userPhone: this.phone,
      userRole: this.role,
      userURL: this.url,
    };
  },
  computed: {},
  mounted() {},
  watch: {},
  methods: {
    userClick: function() {
      if(this.userName == 'Bob Stevens'){
        this.$router.push(this.userURL);
      }
      //
    },
  },
};
</script>
<style lang="scss" scope>
@import '../styles/typovars';
@import '../styles/shared';
.text-ltgrey {
  color: #c3c3c3;
}
.rounded {
  cursor: pointer;
}
</style>
